import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Deck from "./Deck";
import Intro from "./Intro";

function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/deck">
          <Deck />
        </Route>
        <Route path="/">
          <Intro />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
