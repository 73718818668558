import React from "react";
import { Link } from "react-router-dom";
const pStyles = {
  marginTop: "2rem",
  fontSize: "3rem",
};
const Intro = () => {
  return (
    <div
      style={{ backgroundColor: "#ff4551", color: "#f0e8d8", padding: "1rem" }}
    >
      <p className="marker" style={pStyles}>
        Salut Clair,
      </p>
      <p className="marker" style={pStyles}>
        Aujourd'hui tu as 22 ans
      </p>
      <p className="marker" style={pStyles}>
        Donc bon anniversaire BB
      </p>
      <p className="marker" style={pStyles}>
        Derrière ce bouton se trouve plusieurs possibilités de cadeaux. Tu dois
        décider un des cadeaux qui te sera proposé, fais attention ton choix est
        irrévocable.
      </p>
      <Link
        style={{
          backgroundColor: "#f0e8d8",
          color: "#ff4551",
          padding: "1rem",
          fontSize: "2rem",
          display: "block",
          margin: "auto",
          maxWidth: "80%",
          textAlign: "center",
          textDecoration: "none",
          boxShadow: "1rem 1rem 0rem black",
          marginBottom: "20rem",
          marginTop: "8rem",
        }}
        to="/deck"
      >
        BOUTON
      </Link>
    </div>
  );
};

export default Intro;
