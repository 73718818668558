import React, { useState } from "react";
import { useSprings, animated, interpolate } from "react-spring";
import { useGesture } from "react-use-gesture";
import "./styles.css";
import amsterdam from "./amsterdam.jpg";
import bois from "./bois.jpg";
import bruxelles from "./bruxelles.jpg";
import serigraphie from "./serigraphie.jpg";
import bretagne from "./bretagne.jpg";

const cards = [
  {
    title: "Voyage Voyage Amsterdam",
    desc:
      "Bateau, Musée Van Gogh, balade sur les pas de Soizic et quartier rouge <3",
    src: amsterdam,
  },
  {
    title: "Kit Ultimate Sérigraphie",
    desc: "Pour copier le kit de Léa avec le Lisoni ULTIMATE TEXTILE A3+",
    src: serigraphie,
  },
  {
    title: "Voyage Voyage Bruxelles",
    desc:
      "Pour découvrir le berceau de notre beau frère commun (Stefan) et bouffer des gauffres",
    src: bruxelles,
  },
  {
    title: "Atelier menuiserie avec ton BB",
    desc:
      "Pour apprendre les jointures et devenir le prochain Charles & Ray Eames",
    src: bois,
  },
  {
    title: "22 ans = département 22",
    desc: "Donc on va dans les Côtes d'Armor",
    src: bretagne,
  },
];

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

function Deck() {
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, set] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({ args: [index], down, delta: [xDelta], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === cards.length)
        setTimeout(() => gone.clear() || set((i) => to(i)), 600);
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <div className="container">
      <div id="deck">
        {props.map(({ x, y, rot, scale }, i) => (
          <animated.div
            className="cards"
            key={i}
            style={{
              transform: interpolate(
                [x, y],
                (x, y) => `translate3d(${x}px,${y}px,0)`
              ),
            }}
          >
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div
              {...bind(i)}
              style={{
                transform: interpolate([rot, scale], trans),
                backgroundImage: `url(${cards[i].src})`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  backgroundColor: "white",
                  padding: "1rem",
                }}
              >
                <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                  {cards[i].title}
                </div>
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  {cards[i].desc}
                </div>
              </div>
            </animated.div>
          </animated.div>
        ))}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: -1,
            color: "black",
            fontSize: "1.5rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            CHOISIS PAS TOUT ON EST PAS SUR TINDER <br />
            JTM BB
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deck;
